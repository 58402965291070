import { io } from 'socket.io-client';

const host = 'localhost';
const port = 2024;

export const socket = (io as any).connect(`http://${host}:${port}/`, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5000 / 1000,
});

// ? is it the best practice to open the socket here?
setTimeout(() => {
    // if (g.useVsLog)
    socket.on('connect', () => {
        // window.console.log('connected :-)');
        // socket.on('ast', data => {
        //   globalAST.current = data
        // })
        socket.emit('log', { args: ['connected to mm-log'] });
    });
}, 500);

// extra headers
// https://github.com/socketio/socket.io/issues/3929
