// https://pauloe-me.medium.com/typescript-npm-package-publishing-a-beginners-guide-40b95908e69c

import StackTrace from 'stacktrace-js';
import { socket } from './global';
Error.stackTraceLimit = Infinity;

export async function getStackTrace(): Promise<StackTrace.StackFrame[]> {
    return await StackTrace.get();
}

export const mmlog = async (...args: any[]) => {
    const stack = await getStackTrace();
    const error = new Error();
    // window.console.log(args);
    // for some reason...
    // just passing args to the socket will not work
    // it causes a circular reference
    // that results in a stack overflow
    // so we need to make a copy
    // smfh
    const argsCopy = args.map((arg) => {
        return arg;
    });
    // console.log(argsCopy);
    socket.emit('log', {
        args: argsCopy,
        stack,
        error: error.stack,
        processedStack: null,
        // preProcessedStack,
    });
};

// @ts-ignore
if (typeof window === 'undefined') {
    socket.emit('log', { args: ['cannot connect to mm-log'] });
}

// define a new console
var console = (function (oldCons) {
    return {
        log: function (...text: any) {
            oldCons.log(...text);
            // Your code
            mmlog(...text);
        },
        info: function (text: any) {
            oldCons.info(text);
            // Your code
        },
        warn: function (text: any) {
            oldCons.warn(text);
            // Your code
        },
        error: function (text: any) {
            oldCons.error(text);
            // Your code
        },
    };
})(window.console);

// //Then redefine the old console
// @ts-ignore
window.console = console;

// console.log = function (message) {
//     mmlog(message);
// };
// console.log = mmlog;
// console.error = mmlog;
// console.warn = mmlog;
